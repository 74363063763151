import {useTranslation} from 'next-i18next';
import {useRouter} from 'next/router';
import {useEffect, useState} from 'react';
import {Control, useController} from 'react-hook-form';

import {Box, InputAdornment} from '@mui/material';

import formatNumber from 'utils/formateHelpers/formatNumber';
import {TMergedErrors} from 'utils/forms/mergeErrors';

import {TextField} from 'components/basic-components';
import {CustomIcon} from 'components/basic-components/CustomIcon/CustomIcon';
import FormErrors from 'components/basic-components/FormErrors';
import {withErrorBoundary} from 'components/error/ErrorBoundary';

import styles from './styles/formInputDistance.module.scss';

const InputDistance = ({
  control,
  errors,
  maxLength,
  regex,
  isWidget,
  disabled,
}: {
  control: Control<any, any>;
  errors: TMergedErrors;
  maxLength?: number;
  regex?: RegExp;
  isWidget: boolean;
  disabled: boolean;
}) => {
  const [t] = useTranslation('bpr');
  const {locale} = useRouter();
  const [displayValue, setDisplayValue] = useState('');

  const {
    field: {onChange, onBlur, value, name, ref},
  } = useController({control, name: 'distance'});

  useEffect(() => {
    if (value) {
      const formattedDistance = formatNumber(value, locale);
      setDisplayValue(formattedDistance);
    }
  }, [value, locale]);

  const handleChange = e => {
    const regexp = regex || /^[0-9\b]+$/;
    let value = e.target.value;
    if (
      (value === '' || regexp.test(value.replace(/[.,]/g, ''))) &&
      (!maxLength || value?.length <= maxLength)
    ) {
      const numericValue = value.replace(/\D/g, '');

      if (numericValue) {
        const formattedValue = formatNumber(numericValue, locale);
        setDisplayValue(formattedValue);
      } else {
        setDisplayValue('');
      }

      onChange(numericValue);
    }
  };

  return (
    <Box sx={{width: '100%'}}>
      <TextField
        ref={ref}
        variant={isWidget ? 'standard' : 'filled'}
        className={`${styles.numberInput} ${errors?.distance ? 'error' : ''}`}
        fullWidth
        name={name}
        disabled={disabled}
        inputProps={{inputMode: 'numeric'}}
        onBlur={onBlur}
        onChange={handleChange}
        value={displayValue}
        placeholder={t('kilometerPlaceholder')}
        label={t('plannedKilometers')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CustomIcon
                name="tachometer"
                style={{
                  height: '24px',
                  width: '24px',
                }}
              />
            </InputAdornment>
          ),
        }}
      />
      <FormErrors errors={errors.distance} />
    </Box>
  );
};
export default withErrorBoundary(InputDistance, 'InputDistance');
