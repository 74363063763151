import {GroupTypesMappingContext} from 'context/GroupTypesMappingContext';
import {useRouter} from 'next/router';
import {useCallback, useContext} from 'react';

export const useGetTranslatedCategory = () => {
  const {locale} = useRouter();
  const categoryTypeMapping = useContext(GroupTypesMappingContext);
  const getTranslatedKey = useCallback(
    key => categoryTypeMapping?.['de']?.category?.[key]?.[locale] || key,
    [locale, categoryTypeMapping],
  );
  return getTranslatedKey;
};
