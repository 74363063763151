import React from 'react';

import {Box} from '@mui/material';

import {Station} from 'lib/api/backend.schemas';

import {Typography} from 'components/basic-components';
import {withErrorBoundary} from 'components/error/ErrorBoundary';

import styles from './styles/stationList.module.scss';
import {IStationWithDistance} from './types';

const StationListElement = ({
  station,
  onClick,
  hoverHandler,
  isActive = false,
}: {
  hoverHandler: (station: Station) => void;
  station: IStationWithDistance;
  onClick: (station: IStationWithDistance) => void;
  isActive?: boolean;
}) => {
  return (
    <Box
      component="li"
      className={`${styles.element} ${isActive ? styles['_active'] : ''}`}
      onMouseEnter={() => hoverHandler(station)}
      onClick={() => onClick(station)}
      sx={{display: 'flex', alignItems: 'center'}}
    >
      <Typography className={styles.stationName}>{station.description}</Typography>
      <span>{station.distance && <> ({station.distance.toFixed(2)} km)</>}</span>
    </Box>
  );
};

export default withErrorBoundary(StationListElement, 'StationListElement');
