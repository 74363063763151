import isEqual from 'lodash/isEqual';
import {useTranslation} from 'next-i18next';
import React from 'react';

import {SxProps, Typography} from '@mui/material';

import {Station} from 'lib/api/backend.schemas';

import {formatDate, formatTime} from 'utils/date/formatDate';

import {Box, Grid, RemoteStationInformation} from 'components/basic-components';
import {withErrorBoundary} from 'components/error/ErrorBoundary';

import styles from './styles/stationInformation.module.scss';

export const BusinessHours = ({station, sx}: {station: Station; sx?: SxProps}) => {
  const {t} = useTranslation('common');
  if (!station) return null;

  return (
    <Box sx={{display: 'flex', ...sx}}>
      <Box className={styles.businessDaysWrapper}>
        <Typography>{t('moFr')}:</Typography>
        <Typography>{t('sa')}:</Typography>
        <Typography>{t('su')}:</Typography>
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column'}}>
        {station?.businessHours.all[1].closed === false ? (
          <Typography>
            {formatTime(station?.businessHours.all[1].from)} -{' '}
            {formatTime(station?.businessHours.all[1].to)}
          </Typography>
        ) : (
          <Typography>{t('closed')}</Typography>
        )}
        {station?.businessHours.all[6].closed === false ? (
          <Typography>
            {formatTime(station?.businessHours.all[6].from)} -{' '}
            {formatTime(station?.businessHours.all[6].to)}
          </Typography>
        ) : (
          <Typography>{t('closed')}</Typography>
        )}
        {station?.businessHours.all[0].closed === false ? (
          <Typography>
            {formatTime(station?.businessHours.all[0].from)} -{' '}
            {formatTime(station?.businessHours.all[0].to)}
          </Typography>
        ) : (
          <Typography>{t('closed')}</Typography>
        )}
      </Box>
    </Box>
  );
};

export const Address = ({station}: {station: Station}) => {
  const {t} = useTranslation(['bpr', 'common']);
  if (!station) return null;

  return (
    <Box>
      <Box>
        {station?.isRelatedStation && (
          <Typography fontWeight={700}>{t('relatedStation', {ns: 'common'})}</Typography>
        )}
        {station?.isRelatedStation && (
          <Typography fontWeight={700}>{station?.description}</Typography>
        )}

        <Typography>{station?.street}</Typography>
        <Typography>
          {station?.zip} {station?.city}
        </Typography>
        <Typography>{station?.subName ? station?.subName : '\u00A0'}</Typography>
      </Box>
      <Box sx={{margin: '10px 0'}}>
        <Typography>
          {station?.phone ? (
            <a style={{color: 'inherit'}} href={`tel:${station?.phone}`}>
              {t('telefon', {ns: 'common'})}: {station?.phone}
            </a>
          ) : (
            '\u00A0'
          )}
        </Typography>
        <Typography>
          {station?.fax ? `${t('fax', {ns: 'common'})}: ${station?.fax}` : '\u00A0'}
        </Typography>
        <Typography>
          {station?.email ? (
            <a style={{color: 'inherit'}} href={`mailto:${station?.email}`}>
              {station?.email}
            </a>
          ) : (
            '\u00A0'
          )}
        </Typography>
      </Box>
      <Box>
        {station.closingDate && (
          <Typography className={styles.headline}>
            <span>
              {t('closingInfo', {ns: 'common'})}: {formatDate(station.closingDate)}
            </span>
          </Typography>
        )}
        {station.movingDate && (
          <>
            <Typography className={styles.headline}>
              {t('newAddressFrom')} {formatDate(station.movingDate)}
            </Typography>
            <Box component="address">
              <Typography>{station?.movingStreet}</Typography>
              <Typography>
                {station?.movingZip} {station?.movingCity}
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

const StationInformation = ({station}: {station: Station}) => {
  const {t} = useTranslation(['bpr', 'common']);
  if (!station) return null;

  return (
    <Grid container className={styles.gridContainer}>
      {station?.remoteStation ? <RemoteStationInformation /> : null}
      <Grid xxs={5.8}>
        {station?.remoteStation ? (
          <Box>
            <Typography fontWeight={700}>{t('meetingPoint', {ns: 'common'})}</Typography>

            <Typography>{station?.street}</Typography>
            <Typography>
              {station?.zip} {station?.city}
            </Typography>
            <Typography>{station?.subName ? station?.subName : '\u00A0'}</Typography>
            {station?.email ? (
              <Typography>
                <a style={{color: 'inherit'}} href={`mailto:${station.email}`}>
                  {station.email}
                </a>
              </Typography>
            ) : null}
            <BusinessHours station={station?.relatedStation} sx={{marginTop: '10px'}} />
          </Box>
        ) : (
          <BusinessHours station={station} />
        )}
      </Grid>
      <Grid xxs={5.8}>
        {station?.remoteStation && station?.relatedStation ? (
          <Address station={station.relatedStation} />
        ) : (
          <Address station={station} />
        )}
      </Grid>
    </Grid>
  );
};

export default React.memo(withErrorBoundary(StationInformation, 'StationInformation'), isEqual);
