import * as yup from 'yup';

import {CalendarDate} from '@internationalized/date';

const yupSchema = t => {
  const stationValidation: any = ([stationTextField], schema: any, value: any) => {
    if (!value.length && !stationTextField?.length) {
      return schema.required(t('selectStation', {ns: 'bpr'}));
    }
    if (stationTextField?.length) {
      return schema.required(t('selectStation', {ns: 'bpr'}));
    }
  };

  const schema = yup.object({
    category: yup.string(),
    stationTextField: yup.string(),
    group: yup.string(),
    vehicle: yup.string().nullable(),
    departureTime: yup.string().required(t('selectDepartureTime', {ns: 'bpr'})),
    arrivalTime: yup.string().required(t('selectArrivalTime', {ns: 'bpr'})),
    station: yup
      .string()
      .required(t('selectStation', {ns: 'bpr'}))
      .when(['stationTextField'] as string[], stationValidation),
    departure: yup
      .mixed<CalendarDate>()
      .nullable()
      .required(t('selectRentalStart', {ns: 'bpr'})),
    arrival: yup
      .mixed<CalendarDate>()
      .nullable()
      .required(t('selectRentalEnd', {ns: 'bpr'})),
    distance: yup.string().when([], () => {
      return yup
        .number()
        .transform(value => (isNaN(value) ? undefined : value))
        .typeError(t('numberShouldBeNumeric', {ns: 'bpr'}))
        .notRequired()
        .nullable()
        .min(1, t('numberShouldBeAtLeast1', {ns: 'bpr'}));
    }),
  });
  return schema;
};

export default yupSchema;
